<template>
  <div class="details-info__body">
    <template v-if="details && details.patientInsurance">
      <div class="details-info__section">
        <h4 class="title">Insurance</h4>
        <h5 class="insurance_warning" v-if="!ribbonValid">
          Insurance details might be outdated. Check back later
        </h5>
        <h5 class="insurance_warning" v-if="ribbonValid && !ribbonPositive">
          We were unable to verify benefits. We'll keep trying so check back
        </h5>
        <div
          class="details-info__inner"
          v-if="!(ribbonValid && !ribbonPositive)"
        >
          <div class="details-info__side">
            <div class="details-info__item details-info__item_center">
              <div class="details-info__item-title">Deductible</div>
              <div class="details-info__item-body">
                ${{ priceFormat(deductibleMet) }} of ${{
                  priceFormat(deductible)
                }}
                <div class="progress">
                  <svg class="progress__ring" width="80" height="80">
                    <circle
                      class="progress__circle"
                      stroke="#ddd"
                      stroke-width="5"
                      fill="transparent"
                      r="30"
                      cx="40"
                      cy="40"
                    />
                    <circle
                      class="progress__circle"
                      stroke="#2CAA2B"
                      stroke-width="5"
                      fill="transparent"
                      r="30"
                      cx="40"
                      cy="40"
                      v-bind:style="calcPaymentProgress"
                    />
                  </svg>
                  <div class="progress__cur">{{ dedMetPercent }}%</div>
                </div>
              </div>
            </div>
          </div>
          <div class="details-info__side">
            <div class="details-info__item" v-if="companyName">
              <div class="details-info__item-title">Primary</div>
              <div class="details-info__item-body">
                {{ companyName }}
              </div>
            </div>
            <div class="details-info__item" v-if="coInsurancePercent">
              <div class="details-info__item-title">Coinsurance</div>
              <div class="details-info__item-body">
                {{ coInsurancePercent }}%
              </div>
            </div>
            <div class="details-info__item" v-if="effectiveDate">
              <div class="details-info__item-title">Effective Date</div>
              <div class="details-info__item-body">
                {{ effectiveDate }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="details-info__section"
        v-if="
          details &&
          details.patientInsurance &&
          !(ribbonValid && !ribbonPositive)
        "
      >
        <h4 class="title">Estimated Charge</h4>
        <div class="details-info__amount">
          <div
            class="details-info__amount-body"
            v-bind:class="{ _activedetail: isActive }"
          >
            <div class="details-info__amount-btn" v-on:click="showDetailsList">
              Details
            </div>
            <div
              class="details-info__amount-list"
              v-bind:class="{ _hide: !isActive }"
            >
              <div class="details-info__amount-item">
                <div class="details-info__amount-item-label">
                  <div class="details-info__amount-subitem">
                    prenatal office visits<br />
                    postpartum office visit labor and delivery
                  </div>
                </div>
                <div class="details-info__amount-item-value">
                  ${{ priceFormat(allowable) }}
                </div>
              </div>
            </div>
            <div
              class="
                details-info__amount-item
                details-info__amount-item_total
                details-info__amount-item_header
              "
            >
              <div class="details-info__amount-item-label">Total</div>
              <div class="details-info__amount-item-value">
                ${{ priceFormat(allowable) }}
              </div>
            </div>
          </div>
          <div
            class="details-info__amount-item details-info__amount-item_total"
          >
            <div class="details-info__amount-item-label">Your Plan Pays</div>
            <div class="details-info__amount-item-value">
              ${{ priceFormat(planPays) }}
            </div>
          </div>
          <div
            class="details-info__amount-item details-info__amount-item_balance"
          >
            <div class="details-info__amount-item-label">You Pay</div>
            <div class="details-info__amount-item-value">
              ${{ priceFormat(patientCost) }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="details-info__section"
        v-if="
          details &&
          details.patientInsurance &&
          !(ribbonValid && !ribbonPositive)
        "
      >
        <div class="details-table-wrapper">
          <table class="details-table details-table_no-margin">
            <tfoot>
              <tr class="details-payment__balance">
                <td>Unpaid balance:</td>
                <td>{{ priceFormat(patientBalance) }}</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </template>
    <div class="details-info__description details-info__description_top">
      We’ve contacted your insurance to verify coverage. Here’s a breakdown of
      your coverage and your financial responsibility. All payments go towards
      your deductible
    </div>
    <div
      v-if="!hideDescription"
      class="details-info__description details-info__description_bottom"
    >
      *Other services maybe billed separately or may increase the estimated
      amount due: ultrasounds, labs, c-section, non-stress test, hospital,
      anesthesiologist, pediatrician. Total cost for an average pregancy can be
      $7,500-$10,000 before insurance.
    </div>
  </div>
</template>

<script>
import { helpers } from "@/mixins/helpers";

export default {
  mixins: [helpers],
  props: {
    details: {
      type: Object,
      required: true,
    },
    hideDescription: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      loadingData: false,
      showDetails: false,
      showHistory: false,
      payments: [],
      isPrepay: false,
      isActive: false,
    };
  },
  mounted() {
    if (this.$route.query.view && this.$route.query.view === "details") {
      this.showDetails = true;
    }
  },

  watch: {
    "$route.query"({ view }) {
      if (view && view === "details") {
        this.showDetails = true;
      } else {
        this.showDetails = false;
      }
    },
    showDetails(bValue) {
      this.$emit("change", bValue);
    },
  },
  computed: {
    user() {
      return this.$store.getters['patientPATIENT'];
    },
    companyName() {
      return this.details.patientInsurance.company_name || null;
    },
    denominator() {
      return this.$store.getters['customer/CUSTOMER']?.denominator;
    },
    hideAllowable() {
      return this.$store.getters['customer/CUSTOMER']?.hideAllowable;
    },
    ribbonValid() {
      return this.details.patientInsurance.ribbonValid || false;
    },
    ribbonPositive() {
      return this.details.patientInsurance.ribbonPositive || false;
    },
    deductible() {
      return this.details.patientInsurance.deductible || 0;
    },
    deductibleMet() {
      return this.details.patientInsurance.deductible_met || 0;
    },
    dedMetPercent() {
      if (!this.deductible) return 0;
      else {
        return parseInt((this.deductibleMet * 100) / this.deductible);
      }
    },
    familyDeductible() {
      return this.details.patientInsurance.family_deductible || 0;
    },
    effectiveDate() {
      return (
        this.dateFormat(this.details.patientInsurance.effective_date) || null
      );
    },
    coInsurancePercent() {
      return this.details.patientInsurance.co_insurance_percent || 0;
    },
    coInsurancePercentDiff() {
      return this.details.patientInsurance.co_insurance_percent_delta || 0;
    },
    coInsuranceAmount() {
      return this.details.patientInsurance.coinsurance_after_deductible || 0;
    },
    estimatedInsuranceAmount() {
      return (
        this.details.patientInsurance.estimated_insurance_contribution || 0
      );
    },
    allowable() {
      return this.details.patientInsurance.allowable || 0;
    },
    patientCost() {
      return this.details.patient.cost || 0;
    },
    patientBalance() {
      let calcBalance = +this.user.cost - this.user.paid_off;
      return calcBalance >= 0 ? calcBalance : 0;
    },
    planPays() {
      let planPays = this.details.patientInsurance.allowable - this.user.cost;
      return planPays >= 0 ? planPays : 0;
    },
    paidBalance() {
      return this.user.paid_off;
    },
    calcMinPayment() {
      return this.patientBalance > this.minPayment
        ? this.minPayment
        : this.patientBalance;
    },
    minPayment() {
      if (this.details && this.details.patient) {
        let paymentInfo = this.details.patient;
        return paymentInfo.cost ? paymentInfo.cost / this.denominator : 0;
      }
      return 0;
    },
    amountPrepayTransactions() {
      let amountOfTransactions = 0;
      for (let payment of this.payments) {
        amountOfTransactions += +payment.transactionAmount;
      }
      return amountOfTransactions;
    },
    calcPaymentProgress() {
      let dashArray = 60 * Math.PI,
        offset = 0;
      if (this.deductible) {
        offset = dashArray - dashArray * (this.dedMetPercent / 100);
      }

      return {
        strokeDasharray: `${dashArray} ${dashArray}`,
        strokeDashoffset: offset,
      };
    },
  },
  methods: {
    async toggleHistory() {
      if (this.loadingData) return;
      if (!this.showHistory && !this.isPrepay) {
        this.loadingData = true;
        // Get payment history
        await this.$store.dispatch("getPaymentHistory");
        this.payments = this.user.patientHistory
          ? this.user.patientHistory
          : [];
        this.amountPrepayTransactions
          ? (this.isPrepay = true)
          : (this.isPrepay = false);
        this.showHistory = !this.showHistory;
        this.loadingData = false;
      } else {
        this.showHistory = !this.showHistory;
      }
    },
    showDetailsList() {
      this.isActive = !this.isActive;
    },
  },
};
</script>

<style lang="scss">
@import "@/sass/variables.scss";

.insurance_warning {
  text-align: center;
  color: #dc3545;
}
.details-sub-table {
  display: flex;
  align-items: center;
  &__item {
    & + & {
      margin-left: 10px;
    }
  }
}
.highlight {
  color: red;
}
.details-info {
  border: 1px solid $c_grey;
  border-radius: 25px;

  &__button {
    position: relative;
    text-align: center;
    font-size: 24px;
    line-height: 1.375;
    font-weight: 500;
    padding: 0.35em 1em;
    color: #1d2951;
    opacity: 0.6;
    transition: 0.3s all;
    cursor: pointer;
    user-select: none;
  }
  &__body {
    padding: 10px 8px 10px;
    color: $c_grey_2;
  }
  &__title {
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px;
  }
  &__description {
    font-size: 13px;
    padding: 0 5px;
    &_top {
      margin-bottom: 20px;
    }
    &_bottom {
      margin-top: 20px;
    }
  }
  &__section {
    margin-bottom: 20px;
    background: white;
    border-radius: 12px;
    padding: 10px;
    .title {
      font-size: 16px;
      color: $c_font1;
      margin-bottom: 5px;
      text-align: center;
    }
  }
  &__inner {
    display: flex;
    align-items: flex-start;
    align-content: center;
    justify-content: space-around;
  }
  &__item {
    color: $c_font1;
    font-size: 14px;
    margin-top: 30px;
    &:first-child {
      margin-top: 0;
    }
    &_center {
      text-align: center;
    }
  }
  &__item-title {
    margin-bottom: 10px;
  }
  &__item-body {
    font-weight: 400;
  }
  &__amount {
    margin-left: 30px;
    margin-right: 30px;
  }
  &__amount-body {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    border-bottom: 1px solid $c_border;
    padding-bottom: 8px;
    margin-bottom: 14px;
    &._activedetail {
      align-items: flex-start;
      .details-info__amount-item {
        &_header {
          width: 100%;
          padding-right: 0;
          text-align: left;
          .details-info__amount-item-label {
            justify-content: flex-start;
          }
        }
      }
    }
  }
  &__amount-list {
    width: 100%;
    &._hide {
      display: none;
    }
  }
  &__amount-item {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    font-size: 13px;
    padding: 10px 0;
    text-align: left;
    &_total {
      padding: 3px 0;
      font-size: 14px;
      font-weight: 600;
      flex: 1 0 auto;
    }
    &_balance {
      font-size: 23px;
      color: #0fb269;
      font-weight: 600;
      padding-top: 5px;
    }
    &_header {
      padding-right: 45px;
      .details-info__amount-item-label {
        justify-content: center;
      }
    }
  }
  &__amount-item-label {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-right: 15px;
    width: 50%;
  }
  &__amount-item-value {
    width: 50%;
    text-align: inherit;
    padding-left: 15px;
  }
  &__amount-subitem {
    &_left {
      padding-right: 10px;
      flex: 0 0 auto;
    }
  }
  &__amount-btn {
    font-size: 14px;
    cursor: pointer;
    color: #40acf6;
  }
}
.progress {
  position: relative;
  &__ring {
    transform: rotate(-90deg);
  }
  &__cur {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $c_font1;
    font-weight: 600;
    font-size: 18px;
    line-height: 1;
  }
}
.details-payment {
  td {
    border: none;
    font-size: 16px;
    font-weight: 400;

    &:first-child {
      padding-left: 30px;
    }
  }
  &__balance {
    td {
      border: none;

      &:first-child {
        padding-left: 30px;
      }
    }
    font-size: 16px;
    font-weight: 600;
    color: #474765;
  }
}
</style>
