<template>
  <div class="autopay-information">
    <Loader v-if="updatingDataLoading" text="Updating data..." />
    <div class="payment-plan">
      <div class="payment-plan__header">Payment Plan</div>
      <div
        v-if="prePayAvailable && selectedPlan === 'prePay'"
        class="payment-plan__full-width"
      >
        <div class="payment-plan__header-balance">
          Balance ${{ prepay_payment_batch.total_amount }}
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ml-10"
                v-bind="attrs"
                v-on="on"
                v-if="prepay_payment_batch.id"
                @click.prevent="updateBalance(prepay_payment_batch.id)"
              >
                Reset
              </v-btn>
            </template>
            <span>Reset Autopay Amount</span>
          </v-tooltip>
        </div>
        <v-form v-model="monthlyPaymentInputValid">
          <v-text-field
            type="number"
            prefix="$"
            suffix="/Mo"
            v-model="prepay_payment_batch.regular_payment_amount"
            :rules="inputRules"
            label="Monthly Payment Amount"
          ></v-text-field>
          <div>
            <v-flex>
              <v-menu
                v-model="showFirstDateSelector"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :value="prepay_payment_batch.start_from"
                    clearable
                    label="First payment date"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="prepay_payment_batch.start_from"
                  @change="updatePaymentDisplayDate"
                  :allowed-dates="allowedDates"
                >
                </v-date-picker>
              </v-menu>
            </v-flex>
          </div>
        </v-form>

        <div>
          <v-switch
            id="prepay_autopay"
            v-model="prepay_payment_batch.batch_is_active"
            :label="`Autopay ${
              prepay_payment_batch.batch_is_active ? 'Enabled' : 'Disabled'
            }`"
          ></v-switch>
        </div>

        <v-btn color="primary" @click.prevent="confirmPaymentPlan('prePay')">
          Confirm Payment Plan
        </v-btn>
        <v-btn class="ml-5" @click="$emit('closeAutopayPlan')"> Close </v-btn>
      </div>

      <div
        v-if="postPayAvailable && selectedPlan === 'postPay'"
        class="payment-plan__full-width"
      >
        <div class="payment-plan__header-balance">
          Balance ${{ postpay_payment_batch.total_amount }}
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ml-10"
                v-bind="attrs"
                v-on="on"
                v-if="postpay_payment_batch.id"
                @click.prevent="updateBalance(postpay_payment_batch.id)"
              >
                Reset
              </v-btn>
            </template>
            <span>Reset Autopay Amount</span>
          </v-tooltip>
        </div>

        <v-form v-model="monthlyPaymentInputValid">
          <v-text-field
            type="number"
            prefix="$"
            suffix="/Mo"
            v-model="postpay_payment_batch.regular_payment_amount"
            :rules="inputRules"
            label="Monthly Payment Amount"
          ></v-text-field>
          <div>
            <v-flex>
              <v-menu
                v-model="showFirstDateSelector"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :value="postpay_payment_batch.start_from"
                    clearable
                    label="First payment date"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="postpay_payment_batch.start_from"
                  @change="updatePaymentDisplayDate"
                  :allowed-dates="allowedDates"
                >
                </v-date-picker>
              </v-menu>
            </v-flex>
          </div>
        </v-form>

        <div>
          <v-switch
            id="prepay_autopay"
            v-model="postpay_payment_batch.batch_is_active"
            :label="`Autopay ${
              postpay_payment_batch.batch_is_active ? 'Enabled' : 'Disabled'
            }`"
          ></v-switch>
        </div>

        <v-btn color="primary" @click.prevent="confirmPaymentPlan('postPay')">
          Confirm Payment Plan
        </v-btn>
        <v-btn class="ml-5" @click="$emit('closeAutopayPlan')"> Close </v-btn>
        <div v-if="postPayAvailable" class="bill__list">
          <div class="bill__header">Statements Included</div>
          <div
            class="bill__wrap"
            :ref="i"
            v-for="(visit, i) in visits"
            :key="i"
          >
            <div
              class="bill__card"
              :class="{ collapsed: collapsedData(i), uncollapsed: isData(i) }"
            >
              <div class="bill__status"></div>
              <div class="bill__content">
                <div class="bill__header">
                  <div class="bill__info">
                    <div class="bill__date">
                      {{ formatDate(visit.procs[0].procDate) }}
                    </div>
                    <div class="bill__desc">Visit# {{ i }}</div>
                  </div>
                  <div class="bill__desc">{{ visit.concatName }}</div>
                </div>
                <div
                  class="bill__amount bill__amount-collapsed"
                  v-show="collapsedData(i)"
                >
                  Due ${{ priceFormat(visit.calcSum) }}
                </div>
                <div class="bill__amount" v-show="isData(i)" @click="hideBill">
                  Total Due ${{ priceFormat(visit.calcSum) }}
                </div>

                <transition
                  name="fade"
                  @enter="enter"
                  @afterEnter="afterEnter"
                  @afterLeave="afterLeave"
                  @leave="leave"
                >
                  <div class="bill__table-wrap" v-show="isData(i)">
                    <table class="details-table bill__table" ref="table">
                      <tbody v-for="(proc, j) in visit.procs" :key="j">
                        <tr>
                          <td colspan="2" class="bill__table-description">
                            {{ proc.procDesc }} ({{ proc.procCode }})
                          </td>
                        </tr>
                        <tr>
                          <td class="noborder">Total Charge</td>
                          <td class="noborder">
                            ${{ priceFormat(proc.fullPrice) }}
                          </td>
                        </tr>
                        <tr>
                          <td class="noborder">Your Previous Payment</td>
                          <td class="noborder">
                            -${{ priceFormat(proc.patPaid) }}
                          </td>
                        </tr>
                        <tr>
                          <td class="noborder">Insurance & Adjustment</td>
                          <td class="noborder">
                            -${{ priceFormat(proc.adjSum) }}
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td>Your balance</td>
                          <td>${{ priceFormat(visit.calcSum) }}</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </transition>
                <button
                  class="btn btn-third bill__button"
                  v-show="isData(i)"
                  @click.prevent="hideBill"
                >
                  Hide details
                </button>
                <button
                  class="btn btn-third bill__button"
                  v-show="collapsedData(i)"
                  @click.prevent="showBill(i)"
                >
                  View full details
                </button>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import { helpers } from '@/mixins/helpers';
import Loader from '@/components/loader/loader.vue';

const { mapGetters: customerGetters } = createNamespacedHelpers('customer');

export default {
  name: 'AutopayOptionsPopup',
  mixins: [helpers],
  components: {
    Loader,
  },
  props: {
    paymentDetails: {},
    selectedPlan: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      updatingDataLoading: false,
      preloaderText: 'Saving your settings...',
      patient: null,
      monthlyPaymentInputValid: null,
      monthlyPaymentInputVal: '',
      checkboxOn: null,
      checkboxOff: false,
      showNotification: false,
      message: {
        title: '',
        text: '',
      },
      paymentDate: null,
      prepay_payment_batch: {
        payment_type: null,
        notes: null,
        batch_is_active: false,
        total_amount: 0.0,
        regular_payment_amount: 0.0,
        paid_amount: 0.0,
        schedule: '1',
        last_payment_date: null,
        next_payment_date: null,
      },
      postpay_payment_batch: {
        payment_type: null,
        notes: null,
        batch_is_active: false,
        total_amount: 0.0,
        regular_payment_amount: 0.0,
        paid_amount: 0.0,
        schedule: '1',
        last_payment_date: null,
        next_payment_date: null,
      },
      openedId: null,
      showDateSelector: false,
      showFirstDateSelector: false,
      tempDenominator: null,
    };
  },
  mounted() {
    this.setupData();
  },
  computed: {
    ...customerGetters({
      customer: 'CUSTOMER',
      selected_customer: 'SELECTED_CUSTOMER',
    }),
    autopayActive() {
      return (
        this.postpay_payment_batch.batch_is_active ||
        this.prepay_payment_batch.batch_is_active
      );
    },
    preloader() {
      return this.$store.getters['shared/CONTENT_PRELOADER'];
    },
    postPayAvailable() {
      return (
        this.patient &&
        this.paymentDetails &&
        this.paymentDetails.patient &&
        this.patient.balance &&
        this.patient.balance.overallSum
      );
    },
    prePayAvailable() {
      return (
        this.patient &&
        this.patient.ob_status &&
        this.patient.cost &&
        this.costValid
      );
    },
    visits() {
      return this.patient &&
        this.patient.balance &&
        this.patient.balance.overallSum
        ? this.patient.balance.visits
        : [];
    },
    charges() {
      let chargesIDs = [];
      if (this.visits) {
        for (let visit_id in this.visits) {
          const visit = this.visits[visit_id];
          const visitCharges = visit.procs.map((proc) => proc.chargeID);
          chargesIDs = chargesIDs.concat(visitCharges);
        }
      }
      chargesIDs = chargesIDs.flat();
      return chargesIDs;
    },
    isEmptyCard() {
      return this.card && this.card.lastfour ? true : false;
    },
    costValid() {
      const paid_off = this.patient.paid_off || 0;
      return (
        this.patient &&
        this.patient.cost != null &&
        parseFloat(this.patient.cost) > parseFloat(paid_off)
      );
    },
    prepayCheck() {
      return (
        this.customer &&
        this.customer.customersFeatures &&
        this.customer.customersFeatures.prepayCheck
      );
    },
    card() {
      return this.$store.getters['patient/MAIN_CARD'];
    },
    inputRules() {
      let sum;
      if (this.selectedPlan === 'prePay') sum = this.patient.cost;
      if (this.selectedPlan === 'postPay')
        sum = this.patient.balance.overallSum;
      return [
        (v) => !!v || 'Required',
        (v) => v >= 0 || 'Cannot be less than 0.00',
        (v) => v <= parseFloat(sum) || 'Cannot be above ' + sum,
        // v => v <= parseFloat(this.calcBalance) || 'Cannot be above ' + this.calcBalance,
      ];
    },
  },
  methods: {
    collapsedData(i) {
      return !this.openedId || (this.openedId && this.openedId !== i);
    },
    isData(i) {
      return this.openedId && this.openedId === i;
    },
    getDaysArray() {
      const days = [];
      for (let i = 1; i <= 31; i++) {
        days.push('' + i);
      }
      return days;
    },
    showBill(id) {
      this.openedId = id;
      if (id === 'prepay') {
        setTimeout(() => {
          this.$refs[id].scrollIntoView({
            block: 'start',
            behavior: 'smooth',
            inline: 'nearest',
          });
        }, 300);
      } else {
        setTimeout(() => {
          this.$refs[id][0].scrollIntoView({
            block: 'start',
            behavior: 'smooth',
            inline: 'nearest',
          });
        }, 300);
      }
    },
    hideBill() {
      this.openedId = null;
    },
    enter(el) {
      this.openedElementHeight = el.clientHeight;
      el.style.height = '0px';
      setTimeout(() => {
        el.style.height = this.openedElementHeight + 'px';
      });
    },
    leave(el) {
      this.closedElementHeight = el.clientHeight;
      el.style.height = this.closedElementHeight + 'px';
      setTimeout(() => {
        el.style.height = '0px';
      });
    },
    afterEnter(el) {
      el.style.height = 'auto';
    },
    afterLeave(el) {
      el.style.height = 'auto';
    },
    toggleNotification(message) {
      if (message) {
        this.showNotification = true;
      } else {
        this.showNotification = false;
        this.message['title'] = '';
        this.message['message'] = '';
      }
      document.body.classList.toggle('no-scroll', this.showNotification);
    },
    formatDate(date) {
      if (!date) return 'No date selected';
      else return moment(date).utc().format('L');
    },
    setDate() {
      // this.preferredDate = this.tempPayDate;
      this.showDateSelector = false;
    },
    cancelDate() {
      // this.tempPayDate = this.preferredDate;
      this.showDateSelector = false;
    },
    setupData(reset = false) {
      this.patient = this.$store.getters['patient/PATIENT'];
      this.checkboxOn = this.patient.ob_status ? 'prepay' : 'accpay';
      const activeBatch = cloneDeep(this.$store.getters['patient/AUTOPAYMENT']);
      if (this.prePayAvailable) {
        const existedPrepayBatch = activeBatch?.payment_type == 'prePay';
        if (existedPrepayBatch && !reset) {
          this.prepay_payment_batch = activeBatch;
        } else {
          const denominator = this.patient.denominator
            ? this.patient.denominator
            : 6;
          const defaultDate = moment().date();
          this.prepay_payment_batch = {};
          if (reset) {
            this.prepay_payment_batch = { ...activeBatch };
          }
          this.prepay_payment_batch = {
            ...this.prepay_payment_batch,
            payment_type: 'prePay',
            notes: null,
            batch_is_active: false,
            total_amount: this.patient.cost,
            regular_payment_amount: parseFloat(
              (this.patient.cost / denominator).toFixed(2)
            ),
            paid_amount: this.patient.paid_off,
            schedule: defaultDate,
            last_payment_date: null,
            next_payment_date: null,
          };
        }
        this.updatePaymentDisplayDate();
      }
      if (this.postPayAvailable) {
        const existedPostpayBatch = activeBatch?.payment_type == 'postPay';
        if (existedPostpayBatch && !reset) {
          this.postpay_payment_batch = activeBatch;
        } else {
          const denominator = this.customer.paymentDenominator
            ? this.customer.paymentDenominator
            : 6;
          const defaultDate = moment().date();
          this.postpay_payment_batch = {};
          if (reset) {
            this.postpay_payment_batch = { ...activeBatch };
          }
          this.postpay_payment_batch = {
            ...this.postpay_payment_batch,
            payment_type: 'postPay',
            notes: null,
            batch_is_active: false,
            total_amount: this.patient.balance.overallSum,
            regular_payment_amount: parseFloat(
              (this.patient.balance.overallSum / denominator).toFixed(2)
            ),
            paid_amount: 0,
            schedule: defaultDate,
            last_payment_date: null,
            next_payment_date: null,
          };
        }
        this.updatePaymentDisplayDate();
      }
    },
    allowedDates(val) {
      const today = moment().startOf('day');
      return moment(val).isSameOrAfter(today);
    },
    updatePaymentDisplayDate() {
      const activeBatch = this.prePayAvailable
        ? this.prepay_payment_batch
        : this.postpay_payment_batch;

      let startFrom =
        activeBatch.start_from && moment().isBefore(activeBatch.start_from)
          ? moment(activeBatch.start_from).startOf('day')
          : moment().startOf('day');
      let schedule = startFrom.date();

      let startOfMonth = moment(startFrom).startOf('month').toDate();
      if (
        moment().isSameOrAfter(startFrom) &&
        moment().date() > moment(startOfMonth).date(schedule).date()
      ) {
        // shift date if it already passed
        startOfMonth = moment(startOfMonth)
          .add(1, 'month')
          .startOf('month')
          .toDate();
      }
      this.paymentDate = moment(startOfMonth)
        .date(schedule)
        .startOf('day')
        .toDate();
      this.showFirstDateSelector = false;
      this.$emit('updateAutopayDate', this.paymentDate);
    },
    async confirmPaymentPlan(plan) {
      this.updatingDataLoading = true;
      let activeBatch;
      if (plan === 'prePay') {
        activeBatch = this.prepay_payment_batch;
      } else if (plan === 'postPay') {
        activeBatch = this.postpay_payment_batch;
        activeBatch.notes = this.charges.join(',');
      } else throw new Error(`Unknown autopay type: ${plan}`);

      if (activeBatch.batch_is_active) {
        await this.activateAutopay(activeBatch);
      } else {
        await this.deActivateAutopay(activeBatch);
      }
      this.updatingDataLoading = false;
      this.$emit('closeAutopayPlan');
    },
    async saveDenominator() {
      await window.axios
        .post('/proxy/set-patient-denominator', {
          denominator: this.selectedDenominator,
          id: this.patient.id,
        })
        .then(() => {})
        .catch((e) => {
          // Error
          console.error(e);
        });
    },
    async activateAutopay(batch) {
      let route = '/proxy/api/activate-auto-payment';
      const schedule = batch.start_from ? moment(batch.start_from).date() : 31;
      const customerID =
        this.customer.customer_id === 0
          ? this.selected_customer.customer_id
          : this.customer.customer_id;

      let reqBody = {
        customer_id: customerID,
        pid: this.patient.id,
        batch_id: batch.id,
        parameters: {
          ...batch,
          schedule,
        },
      };

      await window.axios
        .post(route, reqBody)
        .then(async (response) => {
          if (response.data && response.data.success === true) {
            this.$store.commit('patient/SET_AUTOPAYMENT', response.data.output);
            if (this.prePayAvailable)
              this.prepay_payment_batch = response.data.output;
            if (this.postPayAvailable)
              this.postpay_payment_batch = response.data.output;
            this.$store.commit('patient/SET_AUTOPAY', true);
            this.updatePaymentDisplayDate();
          }
        })
        .catch(() => {});
    },
    async updateBalance() {
      this.setupData(true);
    },
    async deActivateAutopay(batch) {
      let route = '/proxy/api/deactivate-auto-payment';
      const customerID =
        this.customer.customer_id === 0
          ? this.selected_customer.customer_id
          : this.customer.customer_id;
      let reqBody = {
        customer_id: customerID,
        pid: this.patient.id,
        batch_id: batch.id,
      };
      await window.axios
        .post(route, reqBody)
        .then(async (response) => {
          if (response.data && response.data.success === true) {
            this.updatePaymentDisplayDate();
            this.$store.commit('patient/SET_AUTOPAYMENT', batch);
            this.$store.commit('patient/SET_AUTOPAY', false);
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss">
@import '@/sass/variables.scss';
@import './autopay.scss';
</style>
