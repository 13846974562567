<template>
  <div v-if="showModal" ref="modal" class="fixed-overlay">
    <div class="modal">
      <div class="modal__container">
        <v-btn
          class="mx-2 close-btn"
          fab
          dark
          small
          color="primary"
          @click="$emit('closeModal')"
        >
          <v-icon dark>mdi-close</v-icon>
        </v-btn>

        <slot name="body"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  components: {},
  props: {},
  data() {
    return {
      showModal: false,
    };
  },
  computed: {},
  mounted() {},
  methods: {
    open() {
      this.showModal = true;
    },
    close() {
      this.showModal = false;
    },
  },
};
</script>

<style lang="scss">
@import "@/sass/variables.scss";
.fixed-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal {
  position: absolute;
  left: 50%;
  top: 150px;
  margin-left: -150px;
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  &__container {
    border-radius: 8px;
    padding: 30px;
    background-color: #fff;
    width: 400px;
    min-height: 94px;
  }
}
</style>
