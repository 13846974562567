<template>
  <div class="manage-cards">
    <div class="manage-cards__list">
      <div v-if="user">
        <div
          v-for="(c, index) in user.cards"
          :key="index"
          class="card-number"
          :class="{
            empty: !isEmptyCard,
            'not-empty': isEmptyCard,
            selected: c.id === (tempSelectedCard && tempSelectedCard.id),
          }"
          @click="toggleCardInfo(c)"
        >
          <div class="card-number__logo" :class="{ 'is-empty': !c.type }">
            <template v-if="c.type">
              <img
                :src="require(`@/assets/images/card-types/${c.type}-dark.png`)"
                alt=""
              />
            </template>

            <template v-else>
              <img src="@/assets/images/card-types/empty.png" alt="" />
            </template>
          </div>
          <div class="card-number__title">
            <template v-if="c.lastfour">
              xxxx-xxxx-xxxx-{{ c.lastfour }}
            </template>
            <template v-else> Enter card number </template>
          </div>
          <div class="card-number__checked" v-if="c.selected"></div>
          <div class="card-number__actions" v-if="isEmptyCard">
            <v-btn
              elevation="2"
              density="compact"
              min-width="30"
              max-width="30"
              :outlined="true"
              :rounded="true"
              type="span"
              @click="deleteCard(c)"
              :loading="isDeleting"
            >
              X
            </v-btn>
          </div>
        </div>
      </div>
      <div
        class="credit-card-info__window"
        v-if="
          user &&
          user.cards &&
          user.cards.length &&
          tempSelectedCard &&
          tempSelectedCard.id
        "
      >
        <div class="credit-card-info__header">Selected credit card data</div>
        <div class="credit-card-info__table details-table-wrapper no-borders">
          <div class="credit-card-info__item">
            Number:
            <span class="credit-card-info__item">
              xxxx-xxxx-xxxx-{{ tempSelectedCard.lastfour }}
            </span>
          </div>
          <div class="credit-card-info__item">
            Type:
            <span class="credit-card-info__item">
              {{ tempSelectedCard.type }}
            </span>
          </div>
          <div class="credit-card-info__item">
            Expiration date:
            <span class="credit-card-info__item">
              {{ tempSelectedCard.date }}
            </span>
          </div>
        </div>
        <v-btn
          :disabled="tempSelectedCard.selected"
          color="primary"
          @click.prevent="selectCard(tempSelectedCard)"
        >
          Select card
        </v-btn>
      </div>
    </div>

    <v-divider v-if="showCardForm"></v-divider>

    <v-btn
      v-if="!showCardForm"
      color="primary"
      @click.prevent="addMethod('card')"
    >
      Add a new Debit/Credit Card
    </v-btn>
    <h3 v-if="showCardForm" class="title">Add a card</h3>
    <credit-card-form
      @submit="addCardInfo"
      @cancel="cancelCard"
      v-if="showCardForm"
    ></credit-card-form>
    <v-divider v-if="showCardForm"></v-divider>
  </div>
</template>

<script>
import creditCardForm from '../credit-card-form/credit-card-form.vue';

export default {
  name: 'manageCards',
  data() {
    return {
      isDeleting: false,
      tempSelectedCard: null,
      showCardForm: false,
    };
  },
  components: {
    creditCardForm,
  },
  computed: {
    user() {
      return this.$store.getters['patient/PATIENT'];
    },
    isEmptyCard() {
      return this.user.card ? false : true;
    },
    mainCard() {
      return this.$store.getters['patient/MAIN_CARD'];
    },
  },
  mounted() {
    this.tempSelectedCard = this.mainCard;
  },
  methods: {
    deleteCard(card) {
      if (this.isDeleting) return;
      this.toggleCardInfo();
      this.isDeleting = true;
      var isConfirm = confirm(
        `Are you sure you want to delete xxxx-xxxx-xxxx-${card.lastfour} card?`
      );
      if (isConfirm) {
        window.axios
          .post('/proxy/api/payment/delete-card', {
            pid: this.user.id,
            cardID: card.id,
          })
          .then((response) => {
            if (response.data && response.data.success === true) {
              let resetCard = {
                id: null,
                type: null,
                lastfour: null,
                date: null,
                exp_year: null,
                exp_month: null,
              };
              this.$store.commit('patient/DELETE_CARD', card);
              if (this.user.cards.length) {
                let isSelectedCard = false;
                // this.user.cards.forEach(card => {
                //   if (card.selected === true) isSelectedCard = true;
                // })
                if (!isSelectedCard) {
                  this.$store.commit(
                    'patient/SET_MAIN_CARD',
                    this.user.cards[this.user.cards.length - 1]
                  );
                  this.$store.commit(
                    'patient/SELECT_CARD',
                    this.user.cards[this.user.cards.length - 1]
                  );
                }
              } else {
                this.$store.commit('patient/SET_MAIN_CARD', resetCard);
              }

              this.isDeleting = false;
            }
          })
          .catch((e) => {
            // Error
            console.log(e);
            this.isDeleting = false;
          });
      } else {
        this.isDeleting = false;
      }
    },
    toggleCardInfo(card) {
      this.showCreditCardInfo = !this.showCreditCardInfo;
      if (card) this.tempSelectedCard = card;
      else this.tempSelectedCard = null;
    },
    async selectCard(card) {
      await window.axios.post('/proxy/api/payment/set-primary-card', {
        pid: this.user.id,
        cardID: card.id,
      });
      this.$store.commit('patient/SET_MAIN_CARD', card);
      this.$store.commit('patient/SELECT_CARD', card);
    },
    addMethod() {
      this.showCardForm = true;
    },
    addCardInfo(card) {
      this.showCardForm = false;

      if (card) {
        if (!this.user.cards)
          this.$store.commit('patient/SET_PATIENT_CARDS', []);
        this.$store.commit('patient/ADD_CARD', card);
        this.$store.commit('patient/SET_MAIN_CARD', card);
        this.$store.commit('patient/SELECT_CARD', card);
      }
    },
    cancelCard() {
      this.showCardForm = false;
    },
  },
};
</script>
<style lang="scss">
@import '@/sass/variables.scss';
@import './manage-cards.scss';
</style>
