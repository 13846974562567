<template>
  <section class="credit-card-information">
    <ValidationObserver>
      <form class="site-form credit-card-form" @submit.prevent="onSubmit">
        <div class="form-group">
          <ValidationProvider
            name="cardnumber"
            rules="required|numeric"
            v-slot="{ errors }"
          >
            <v-text-field
              :v-model="card.number"
              label="Credit card number"
              name="cardnumber"
              @input="fakeCardNumber"
              @change="fakeCardNumber"
            >
            </v-text-field>
            <div class="invalid-data">{{ errors[0] }}</div>
            <div class="credit-card-number-input__invalid" v-if="!card.valid">
              Card number is invalid
            </div>
          </ValidationProvider>
        </div>

        <div class="form-group">
          <ValidationProvider
            name="ccname"
            rules="required"
            v-slot="{ errors }"
          >
            <v-text-field
              label="Cardholder name"
              name="ccname"
              v-model="card.name"
            >
            </v-text-field>
            <div class="invalid-data">{{ errors[0] }}</div>
          </ValidationProvider>
        </div>

        <div class="form-group">
          <label class="form-label">Expiration</label>
          <div class="card-expiration">
            <div class="form-group">
              <ValidationProvider
                name="months"
                rules="required"
                v-slot="{ errors }"
              >
                <v-select
                  v-model="card.exp.month"
                  :items="months"
                  label="Month"
                ></v-select>
                <div class="invalid-data">{{ errors[0] }}</div>
              </ValidationProvider>
            </div>

            <span class="separator"></span>

            <div class="form-group">
              <ValidationProvider
                name="year"
                rules="required"
                v-slot="{ errors }"
              >
                <v-select
                  v-model="card.exp.year"
                  :items="expYears"
                  item-text="name"
                  label="Year"
                ></v-select>
                <div class="invalid-data">{{ errors[0] }}</div>
              </ValidationProvider>
            </div>
          </div>
        </div>

        <div class="form-group">
          <ValidationProvider
            name="Zip code"
            rules="numeric"
            v-slot="{ errors }"
          >
            <v-text-field label="Billing Zipcode" v-model="card.zipcode">
            </v-text-field>
            <div class="invalid-data">{{ errors[0] }}</div>
          </ValidationProvider>
        </div>

        <v-btn
          class="ma-2"
          type="submit"
          color="primary"
          :loading="disableSaveCardBtn"
          :disabled="disableSaveCardBtn"
        >
          Add card
        </v-btn>
        <v-btn class="ma-2" type="submit" @click.prevent="onCancel">
          Cancel
        </v-btn>
      </form>
    </ValidationObserver>
  </section>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { numeric } from "vee-validate/dist/rules";
extend("numeric", numeric);
const valid = require("card-validator");

export default {
  data() {
    return {
      card: {
        number: null,
        name: null,
        type: null,
        exp: {
          month: null,
          year: null,
        },
        valid: true,
        zipcode: null,
      },
      currentYear: null,
      currentMonth: null,
      expYears: [],
      loading: false,
      months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      disableSaveCardBtn: false,
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  computed: {
    user() {
      return this.$store.getters['patient/PATIENT'];
    },
    cardNumber() {
      return this.card.number;
    },
    cardMonth() {
      return this.card.exp.month;
    },
    cardYear() {
      return this.card.exp.year;
    },
    customer() {
      return this.$store.getters['customer/CUSTOMER'];
    },
  },
  watch: {
    cardNumber(val) {
      let numberValidation = valid.number(val);

      if (
        val.length >= 2 &&
        numberValidation.card &&
        numberValidation.card.type
      ) {
        this.card.type = numberValidation.card.type;
      } else {
        this.card.type = null;
      }
    },
    cardYear(val) {
      if (val === this.currentYear && this.cardMonth <= this.currentMonth) {
        this.card.exp.month = null;
      }
    },
  },
  mounted() {
    this.currentYear = new Date().getFullYear();
    this.currentMonth = new Date().getMonth();

    for (let i = 0; i < 16; i++) {
      this.expYears.push(this.currentYear + i);
    }
  },
  methods: {
    fakeCardNumber(event) {
      let $el = event;

      let newVal = $el
        .replace(/\D/gi, "")
        .replace(/(.{4})/g, "$1 ")
        .trim();
      $el = newVal;
      this.card.number = newVal.replace(/\D+/g, "");
      this.card.valid = true;
    },
    monthFormat(val) {
      return ("0" + val).slice(-2);
    },
    onCancel() {
      this.$emit("cancel");
      let query = Object.assign({}, this.$route.query);
      if (query.option) {
        this.$router.push({
          query: {
            option: query.option,
          },
        });
      }
    },
    onSubmit() {
      if (this.disableSaveCardBtn) return;

      this.disableSaveCardBtn = true;

      const cardNumber = this.card.number;

      let numberValidation = valid.number(cardNumber);

      if (!numberValidation.isValid) {
        this.card.valid = false;
        this.loadisableSaveCardBtnding = false;
        return;
      }

      window.axios
        .post("/proxy/api/payment/save-card", {
          pid: this.user.id,
          expMonth: this.monthFormat(this.card.exp.month),
          expYear: this.card.exp.year,
          type: numberValidation.card.type,
          lastfour: cardNumber.toString().slice(-4),
          cardHolderName: this.card.name,
          cardNumber: cardNumber,
          locID: this.customer.CustomersFeature.defaultLocation,
          postalCode: this.card.zipcode,
        })
        .then((response) => {
          if (
            response.data &&
            response.data.success === true &&
            response.data.card
          ) {
            this.$emit("submit", response.data.card);
          } else {
            this.$emit("cancel");
          }

          this.disableSaveCardBtn = false;
        })
        .catch((e) => {
          // Error
          console.log(e);
          this.$emit("cancel");
          this.disableSaveCardBtn = false;
        });
    },
  },
};
</script>

<style lang="scss">
.card-expiration {
  display: flex;
  align-items: center;
  .separator {
    margin: 0 10px;
  }
}
.credit-card-information {
  .form-pair {
    .form-group {
      width: 50%;
    }
  }
  .separator {
    margin: 0 10px;
  }
}
</style>
