<template>
  <v-form v-model="isValid">
    <div>
      <v-text-field
        label="Amount to pay"
        type="number"
        :rules="[
          (v) =>
            (v > 0 && v <= parseFloat(visit.calcSum)) || 'Amount is invalid',
        ]"
        v-model="amountToPay"
      ></v-text-field>
    </div>
    <button
      class="btn btn-primary no-border bill__button"
      @click.prevent="showPayModal"
      type="submit"
      :disabled="!isValid"
    >
      Pay this bill
    </button>
    <button class="btn btn-third bill__button" @click.prevent="hideForm">
      Hide details
    </button>
  </v-form>
</template>

<script>
export default {
  name: 'PostPayForm',
  props: ['openPayModal', 'getCharges', 'hideBill', 'visit', 'index'],
  data() {
    return {
      isValid: false,
      amountToPay: this.visit.calcSum,
    };
  },
  methods: {
    showPayModal() {
      this.openPayModal(
        this.amountToPay,
        this.visit.locID,
        'postPay',
        this.getCharges(this.visit.procs),
        this.index
      );
    },
    hideForm() {
      this.hideBill();
    },
  },
};
</script>
