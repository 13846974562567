<template>
  <div>
    <button
      v-if="paymentType !== 'double'"
      type="button"
      class="btn btn-block btn-success no-border payment-btn"
      :class="{ 'is-loading': loading }"
      @click="pay"
    >
      {{ payButtonText() }}
    </button>
    <button
      v-if="paymentType === 'double'"
      type="button"
      class="btn btn-block btn-success no-border payment-btn"
      :class="{ 'is-loading': loading }"
      @click="doublePayment"
    >
      {{ payButtonText() }}
    </button>
  </div>
</template>

<script>
import { helpers } from "@/mixins/helpers";

export default {
  name: "pay-button",
  data() {
    return {
      loading: false,
      message: {
        title: "Payment was not processed",
        text: "Transaction failed. Please try again later",
      },
    };
  },
  props: {
    paymentData: {
      type: Object,
      required: true,
    },
    paymentType: {
      type: String,
      required: true,
    },
  },
  computed: {
    user() {
      return this.$store.getters['patientPATIENT'];
    },
    customer() {
      return this.$store.getters['customerCUSTOMER'] ? this.$store.getters['customerCUSTOMER'] : {};
    },
  },
  mixins: [helpers],
  methods: {
    payButtonText() {
      return `Pay $${this.priceFormat(this.paymentData.amount)}`;
    },
    pay() {
      if (!this.paymentData.amount) return;
      if (this.loading) return;
      this.loading = true;
      let url;
      if (this.paymentType === "prePay")
        url = "/proxy/api/payment/send-transaction";
      else if (this.paymentType === "postPay")
        url = "/proxy/api/payment/send-balance-transaction";
      window.axios
        .post(url, this.paymentData)
        .then((response) => {
          if (response.data && response.data.success === true) {
            this.$emit("setModalResult");
          } else {
            this.$emit("setModalResult", response.data.error);
          }
          this.loading = false;
          this.$emit("updateDetails");
        })
        .catch((e) => {
          // Error
          this.loading = false;
          this.$emit("setModalResult", e.toString());
          this.$emit("updateDetails");
        });
    },
    doublePayment() {
      this.$emit("loading", true);
      if (this.loading) return;
      this.loading = true;
      let transctionPromises = [];
      let prePayAmount, postPayAmount, transactionPrePay, transactionPostPay;

      prePayAmount = this.paymentData.paymentsArray[0];
      if (prePayAmount) {
        transactionPrePay = this.transactionPrePay();
        transctionPromises.push(transactionPrePay);
      }

      postPayAmount = this.paymentData.paymentsArray[1];
      if (postPayAmount) {
        transactionPostPay = this.transactionPostPay();
        transctionPromises.push(transactionPostPay);
      }

      return Promise.all(transctionPromises)
        .then((resp) => {
          if (
            (resp.length === 1 && resp[0].success) ||
            (resp.length === 2 && resp[0].success && resp[1].success)
          ) {
            this.$emit("setModalResult");
          } else {
            this.$emit("setModalResult", "");
          }
          this.loading = false;
          this.$emit("updateDetails");
        })
        .catch((error) => {
          this.loading = false;
          this.$emit("setModalResult", error);
          this.$emit("updateDetails");
        });
    },
    transactionPrePay() {
      if (!this.paymentData.paymentsArray[0]) return;
      return window.axios
        .post("/proxy/api/payment/send-transaction", {
          pid: this.paymentData.pid,
          card_id: this.paymentData.card_id,
          amount: this.paymentData.paymentsArray[0],
          currency: this.paymentData.currency,
          customer_id: this.paymentData.customer_id,
          denominator: this.paymentData.denominator,
          locID: this.paymentData.locID,
        })
        .then((response) => {
          if (response.data && response.data.success === true)
            return { success: response.data.success };
          else
            return {
              success: response.data.success,
              code: response.data.code,
            };
        })
        .catch((e) => {
          // Error
          console.log(e);
        });
    },
    transactionPostPay() {
      if (!this.paymentData.paymentsArray[1]) return;
      return window.axios
        .post("/proxy/api/payment/send-balance-transaction", {
          pid: this.paymentData.pid,
          card_id: this.paymentData.card_id,
          amount: this.paymentData.paymentsArray[1],
          currency: this.paymentData.currency,
          customer_id: this.paymentData.customer_id,
          denominator: this.paymentData.denominator,
          locID: this.paymentData.locID,
          charges: this.paymentData.charges,
        })
        .then((response) => {
          if (response.data && response.data.success === true)
            return { success: response.data.success };
          else
            return {
              success: response.data.success,
              code: response.data.code,
            };
        })
        .catch((e) => {
          // Error
          console.log(e);
        });
    },
  },
};
</script>
