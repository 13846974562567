<template>
  <modal ref="payModal" class="pay-modal" @closeModal="$refs.payModal.close()">
    <div class="modal__body" v-if="success" slot="body">
      <div class="payment-result">
        <v-icon class="payment-result__icon" color="success">
          mdi-check-circle
        </v-icon>
        <h2>Payment processed successfully</h2>
      </div>
    </div>

    <div
      class="modal__body"
      v-else-if="typeof errorMessage === 'string'"
      slot="body"
    >
      <div class="payment-result">
        <v-icon class="payment-result__icon" color="error">
          mdi-alert-circle
        </v-icon>
        <h2>
          Error occurred while making a payment{{
            errorMessage ? ": " + errorMessage : ""
          }}
        </h2>
      </div>
    </div>

    <div class="modal__body" v-else slot="body">
      <h3 class="checkout-option__title" v-if="!card.id">Please add a card</h3>
      <template v-if="optionTitle && card.id && user">
        <h3 class="checkout-option__title">E-Payment option</h3>
        <section class="payment-options">
          <div class="payment-option">
            <div class="payment-option__title">
              {{ optionTitle }}
            </div>
          </div>
        </section>
        <h3 class="checkout-option__title">My E-Payment method</h3>
        <div
          class="card-number"
          :class="{ empty: !isEmptyCard, 'not-empty': isEmptyCard }"
        >
          <div class="card-number__logo" :class="{ 'is-empty': !card.type }">
            <template v-if="card.type">
              <img
                :src="
                  require(`@/assets/images/card-types/${card.type}-dark.png`)
                "
                alt=""
              />
            </template>
            <template v-else>
              <img src="@/assets/images/card-types/empty.png" alt="" />
            </template>
          </div>
          <div class="card-number__title">
            <template v-if="card.lastfour">
              xxxx-xxxx-xxxx-{{ card.lastfour }}
            </template>
            <template v-else> Enter card number </template>
          </div>
          <div class="card-number__date" v-if="card.date">
            {{ card.date }}
          </div>
        </div>
        <div v-if="card.id">
          <div class="payment-method__pay">
            <pay-button
              :paymentData="paymentData"
              :paymentType="paymentType"
              @updateDetails="$emit('updateDetails')"
              @setModalResult="setModalResult"
            ></pay-button>
            <div
              class="
                payment-method__information payment-method__information_security
              "
            >
              Payment information is TLS encrypted with a PCI Level I compliant
              processor
            </div>
          </div>
        </div>
      </template>
    </div>
  </modal>
</template>

<script>
import modal from "./modal.vue";
import payButton from "../components/pay-button/pay-button.vue";

export default {
  name: "payModal",
  components: {
    payButton,
    modal,
  },
  props: {
    paymentType: {
      required: true,
    },
    paymentData: {
      required: true,
    },
    optionTitle: {
      type: String,
      required: true,
    },
    autopayState: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      success: null,
      errorMessage: null,
    };
  },
  computed: {
    card() {
      return this.$store.getters['patient/MAIN_CARD'];
    },
    isEmptyCard() {
      return this.card.lastfour ? true : false;
    },
    user() {
      return this.$store.getters['patient/PATIENT'];
    },
  },
  mounted() {},
  methods: {
    setModalResult(errorMessage) {
      if (typeof errorMessage === "string") {
        this.success = false;
        this.errorMessage = errorMessage;
      } else {
        this.success = true;
        this.errorMessage = null;
      }
    },
    open() {
      this.success = null;
      this.errorMessage = null;
      this.$refs.payModal.open();
    },
    close() {
      this.$refs.payModal.close();
    },
  },
};
</script>


<style lang="scss">
@import "@/sass/variables.scss";
@import '../_buttons.scss';

.pay-modal {
  .checkout-option {
    &__title {
      text-align: left;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 16px;
      margin-top: 0;
      margin-bottom: 10px;
      color: #9b9b9b;
    }
  }
  .card-number {
    $root: &;

    border-radius: 8px;
    padding-top: 5px;
    padding-bottom: 5px;
    line-height: 40px;
    // color: #9B9B9B;
    &__logo {
      width: 60px;
      text-align: center;
      img {
        max-width: 40px;
        vertical-align: middle;
      }
    }
    &__title {
      margin: 0 15px;
      text-align: left;
    }
    &__date {
      font-size: 14px;
      width: 60px;
      text-align: center;
    }

    &__dot {
      display: block;
      width: 5px;
      height: 5px;
      margin: 2px;
      background-color: $c_grey_1;
      border-radius: 50%;
    }
    &__actions {
      display: flex;
      align-items: center;
      line-height: 1;
    }
    &__delete {
      display: inline-block;
      vertical-align: middle;
      width: 24px;
      height: 24px;
      font-size: 14px;
      font-weight: 700;
      line-height: 22px;
      text-align: center;
      border-radius: 50%;
      border: 1px solid currentColor;
      opacity: 0.75;
      transition: 0.3s all;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
    }
    &__preloader {
      .preloader {
        width: 24px;
        height: 24px;
      }
    }
    /** Mods: */
    &.empty {
      cursor: pointer;
    }
    &.not-empty {
      padding-left: 5px;
      box-shadow: none;
      #{$root}__title {
        font-size: 14px;
      }
    }
  }
  .payment-options {
    margin-bottom: 10px;
    .title {
      text-align: left;
      margin-bottom: 10px;
      margin-top: 10px;
      text-transform: uppercase;
      font-size: 18px;
      color: $c_grey;
    }
  }
  .payment-option {
    min-height: 52px;
    border-radius: 8px;
    padding: 10px 20px;
    line-height: 40px;
    margin-bottom: 0px;
    &__title {
      text-align: left;
      font-size: 14px;
      margin-right: 0;
    }
  }
  .payment-option,
  .method-option,
  .card-number {
    display: flex;
    align-items: center;
    border: 1px solid $c_border;
    border-radius: 8px;
    padding: 8px 10px;
    margin-bottom: 25px;
    width: 100%;
    cursor: inherit;
    &__title {
      flex-grow: 1;
      // font-size: 18px;
      line-height: 1;
      text-align: center;
      margin-right: 15px;
    }
    &__cancel {
      margin-left: auto;
      color: #9b9b9b;
      padding: 4px 6px;
      text-transform: uppercase;
      background-color: #fff;
      border: 2px solid #e1e1e1;
      border-radius: 30px;
      cursor: pointer;
    }
  }
  .payment-method {
    .title {
      text-align: center;
    }
    &__pay {
      margin-top: 110px;
      margin-bottom: 20px;
      &.with-info {
        margin-top: 55px;
      }
    }
    &__information {
      font-size: 14px;
      font-weight: 500;
      color: $c_grey;
      padding-left: 25px;
      & + & {
        margin-top: 15px;
      }
      &_autopay {
        padding-left: 0;
      }
      &_security {
        font-size: 16px;
        background-image: url("../../../assets/images/locked.png");
        background-repeat: no-repeat;
        background-position: 0 0;
        margin-top: 30px;
      }
    }
  }
  .payment-result {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-bottom: 20px;

    &__icon {
      font-size: 90px !important;
      margin: 20px;
    }
  }
}
</style>
