<template>
  <div class="scheduled-payments">
    <div class="bill__list">
      <div class="bill__header">Statements Payments</div>

      <div class="bill__wrap" v-if="prepayAutopay">
        <div class="bill__card">
          <div class="bill__status-prepay"></div>
          <div class="bill__content">
            <table class="details-table bill__table" ref="table">
              <tbody>
                <tr class="bill__table-tr">
                  <td class="noborder bill__data">
                    <span class="bill__balance">Balance: ${{ cost }}</span>
                    <span class="bill__payment"
                      >Next payment: ${{ prepayAmount }}</span
                    >
                  </td>
                  <td
                    class="noborder bill__autopay"
                    @click="$emit('openPrepay')"
                  >
                    <refresh-icon class="icon"></refresh-icon> Autopay
                    {{ prepayDate }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="bill__wrap" v-if="accountAutopay">
        <div class="bill__card">
          <div class="bill__status"></div>
          <div class="bill__content">
            <table class="details-table bill__table" ref="table">
              <tbody>
                <tr class="bill__table-tr">
                  <td class="noborder bill__data">
                    <span class="bill__balance">
                      Total: ${{ patientBalance }}</span
                    >
                    <span class="bill__payment"
                      >Next payment: ${{ accountAmount }}</span
                    >
                  </td>
                  <td
                    class="noborder bill__autopay"
                    @click="$emit('openPostpay')"
                  >
                    <refresh-icon class="icon"></refresh-icon> Autopay
                    {{ postpayDate }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import RefreshIcon from '../../icons/Refresh.vue';

export default {
  name: 'scheduledPayments',
  components: {
    RefreshIcon,
  },
  props: {
    paymentDetails: { required: true },
  },
  data() {
    return {
      patient: null,
      customer: null,
      batch: {},
    };
  },
  computed: {
    paymentDate() {
      if (!this.autopayType) return '';
      return this.autopayDate ? moment(this.autopayDate).utc().format('L') : '';
    },
    cost() {
      return this.patient ? this.patient.cost : 0;
    },
    paid_off() {
      return this.patient.paid_off || 0;
    },
    denominator() {
      if (!this.patient && !this.customer) return 1;
      return this.patient && this.patient.denominator
        ? this.patient.denominator
        : this.customer.paymentDenominator;
    },
    prepayAmount() {
      const restToPay = parseFloat(this.cost) - parseFloat(this.paid_off);
      const monthlyPayment = this.prepay_batch
        ? this.prepay_batch.regular_payment_amount
        : parseFloat((this.cost / this.denominator).toFixed(2));
      if (restToPay < monthlyPayment) return restToPay;
      else return monthlyPayment;
    },
    prepayAutopay() {
      return this.patient?.cost && this.patient?.ob_status;
    },
    accountAutopay() {
      if (this.patient?.balance?.overallSum) return true;
      return false;
    },
    patientBalance() {
      if (this.postpay_batch) return this.postpay_batch.total_amount;
      else
        return this.patient &&
          this.patient.balance &&
          this.patient.balance.overallSum
          ? this.patient.balance.overallSum
          : 0;
    },
    accountAmount() {
      const restToPay =
        this.postpay_batch && this.postpay_batch
          ? this.calcChargesBalance()
          : this.patientBalance;

      const patientAmount = this.postpay_batch
        ? this.postpay_batch.regular_payment_amount
        : parseFloat(this.patientBalance / this.denominator).toFixed(2);

      if (restToPay < patientAmount) return restToPay;
      else return patientAmount;
    },
    prepay_batch() {
      if (this.batch?.payment_type === 'prePay') {
        return this.batch;
      }
      return null;
    },
    prepayDate() {
      return this.prepay_batch
        ? moment(this.prepay_batch.next_payment_date).format('L')
        : null;
    },
    postpay_batch() {
      if (this.batch?.payment_type === 'postPay') {
        return this.batch;
      }
      return null;
    },
    postpayDate() {
      return this.postpay_batch
        ? moment(this.postpay_batch.next_payment_date).format('L')
        : null;
    },
    autopayment() {
      return this.$store.getters['patient/AUTOPAYMENT'];
    },
  },
  watch: {
    autopayment() {
      this.setupData();
    },
  },
  mounted() {
    this.setupData();
  },
  methods: {
    calcChargesBalance() {
      if (this.postpay_batch?.payment_type !== 'postPay')
        return 0.0;
      const chargesList = this.postpay_batch.notes.split(',');

      const chargesBalances = chargesList.map((batchChargeID) => {
        for (var visit_id in this.patient.balance.visits) {
          const visit = this.patient.balance.visits[visit_id];
          const charge = visit.procs.find((cc) => cc.chargeID == batchChargeID);
          if (charge) return charge.balance;
        }
        return 0.0;
      });

      let chargesBalance = chargesBalances.reduce((acc, b) => acc + b, 0.0);
      return parseFloat(chargesBalance.toFixed(2));
    },
    setupData() {
      // patient data
      this.patient = this.$store.getters['patient/PATIENT'];
      this.customer = this.$store.getters['customer/CUSTOMER'];

      // autopay dates (default)
      this.batch = this.$store.getters['patient/AUTOPAYMENT'];

      // this.autopayDate = autopayDates ? autopayDates.payDate : null;
    },
  },
};
</script>

<style lang="scss">
@import '@/sass/variables.scss';
.scheduled-payments {
  position: relative;
  max-width: 500px;
  width: 100%;
  .close-btn {
    position: absolute;
    right: -40px;
    top: -5px;
  }
  .bill {
    &__list {
      padding-top: 0;
    }
    &__card {
      min-height: 150px;
    }
    &__autopay {
      max-width: 90px;
      text-align: center;
      color: rgb(134, 189, 46);
      font-size: 14px;
      cursor: pointer;
    }
    &__content {
      justify-content: flex-end;
      padding: 7px 10px 10px 10px;
    }
    &__balance {
      font-size: 14px;
    }
    &__payment {
      font-size: 18px;
    }
    &__table {
      tr {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      }
    }
    &__data {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }
  .icon {
    fill: rgb(134, 189, 46);
    height: 40px;
    width: 40px;
  }
}
</style>
