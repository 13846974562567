<template>
  <v-container>
    <Loader v-if="updatingPatient" :text="loaderMessage" />
    <div v-else>
      <v-toolbar color="primary" dark class="justify-center">
        {{ patient.firstname }} {{ patient.lastname }}
        <span class="patients-popup__header-btn2" @click="updatePatient">
          Update Patient
        </span>
        <span
          class="patients-popup__header-btn1"
          v-if="patient && patient.cellphone"
          @click="sendLink"
        >
          Send Magic Link
        </span>
      </v-toolbar>

      <div v-if="!loading" class="patient-container">
        <div class="patients-card" v-if="patient">
          <div class="patients-card__header">Patient Info</div>
          <table class="patients-card__table">
            <tbody>
              <tr>
                <td class="patients-card__title">Name</td>
                <td>{{ patient.firstname }} {{ patient.lastname }}</td>
              </tr>
              <tr>
                <td class="patients-card__title">DOB</td>
                <td>{{ patient.dob }}</td>
              </tr>
              <tr>
                <td class="patients-card__title">Account #</td>
                <td>{{ patient.patient_id }}</td>
              </tr>
              <tr>
                <td class="patients-card__title">Cellphone</td>
                <td>{{ patient.cellphone }}</td>
              </tr>
              <tr>
                <td class="patients-card__title">Email</td>
                <td>{{ patient.email }}</td>
              </tr>
              <tr>
                <td class="patients-card__title">Notifications</td>
                <td>
                  <div class="checkbox">
                    <input
                      id="notifications"
                      name="notifications"
                      type="checkbox"
                      v-model="patient.opt_out"
                      @change="changeOptOut"
                    />
                    <label for="notifications"></label>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="patients-card__title">Skip-cost-estimation</td>
                <td>
                  <div class="checkbox">
                    <input
                      id="skipCostEstimation"
                      name="skipCostEstimation"
                      type="checkbox"
                      v-model="patient.skip_cost_extimation"
                      @change="changeSkipCostEstimate"
                    />
                    <label for="skipCostEstimation"></label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="patients-card" v-if="insurance">
          <div class="patients-card__header">Benefits</div>
          <table class="patients-card__table">
            <tbody>
              <tr>
                <td class="patients-card__title">Patient Name</td>
                <td>{{ patient.firstname }} {{ patient.lastname }}</td>
                <td class="patients-card__title">Insurance</td>
                <td>{{ insurance.company_name }}</td>
                <td class="patients-card__title">Group #</td>
                <td>{{ insurance.group_id }}</td>
              </tr>
              <tr>
                <td class="patients-card__title">Account #</td>
                <td>{{ patient.patient_id }}</td>
                <td class="patients-card__title">Plan</td>
                <td>{{ insurance.plan }}</td>
                <td class="patients-card__title">Policy #</td>
                <td>{{ insurance.member_id }}</td>
              </tr>
              <tr>
                <td class="patients-card__title"></td>
                <td></td>
                <td class="patients-card__title">Plan Start</td>
                <td>{{ insurance.effective_date }}</td>
                <td class="patients-card__title">Deductible</td>
                <td>{{ insurance.deductible }}</td>
              </tr>
              <tr>
                <td class="patients-card__title"></td>
                <td></td>
                <td class="patients-card__title">Insured Name</td>
                <td>{{ patient.firstname }} {{ patient.lastname }}</td>
                <td class="patients-card__title">Deductible Met</td>
                <td>{{ insurance.deductible_met }}</td>
              </tr>
              <tr>
                <td class="patients-card__title"></td>
                <td></td>
                <td class="patients-card__title">Member ID</td>
                <td>{{ insurance.member_id }}</td>
                <td class="patients-card__title">Coinsurance</td>
                <td>{{ insurance.co_insurance_percent }}</td>
              </tr>
              <tr>
                <td class="patients-card__title"></td>
                <td></td>
                <td class="patients-card__title"></td>
                <td></td>
                <td class="patients-card__title">Benefits Verified</td>
                <td>{{ insurance.deductible }}</td>
              </tr>
            </tbody>
          </table>
          <div class="patients-popup__details d-flex align-center">
            <ins @click="toggleDetails">Details</ins>
            <div style="z-index: 800">
              <v-select
                class="mx-3"
                outlined
                dense
                v-model="authenticationId"
                item-text="name"
                item-value="id"
                :items="authentications"
                label="Pverify Credentails"
                clearable
              ></v-select>
            </div>
            <v-btn
              icon
              x-small
              color="green"
              @click="updatePatientBenefit"
              :disabled="!authenticationId"
            >
              <v-icon v-if="insLoading">mdi-spin mdi-cached</v-icon>
              <v-icon v-else>mdi-cached</v-icon>
            </v-btn>
          </div>
          <!-- <pre v-if="showDetails">{{ insurance.ribbonResponse }}</pre> -->
          <pre v-if="showDetails">{{ pverifyResponse }}</pre>
        </div>

        <div class="patients-card">
          <div class="patients-card__header">Manage Cards</div>
          <manage-cards></manage-cards>
        </div>

        <div class="patients-card">
          <div class="patients-card__header">Account</div>

          <div
            class="btn patients-card__btn"
            @click="
              doublePayment(
                hidePrePay ? 0 : calcMinPayment,
                balance.overallSum,
                locID,
                'double',
                getCharges(),
                getVisits()
              )
            "
          >
            {{ payButtonText }}
          </div>

          <v-divider></v-divider>
          <div v-if="autopay_type" class="patients-card__autopayment">
            <span class="patients-card__greeen-text"
              >Auto Payment Scheduled</span
            >
            <p v-if="prepay_batch && prepay_batch.batch_is_active">
              ${{ prepay_autopay_amount }} on
              {{ formatPaymentDate(prepay_batch.next_payment_date) }}
            </p>
            <p v-if="postpay_batch && postpay_batch.batch_is_active">
              ${{ postpay_autopay_amount }} on
              {{ formatPaymentDate(postpay_batch.next_payment_date) }}
            </p>
          </div>

          <div v-else class="patients-card__autopayment">
            <span class="patients-card__greeen-text">
              Auto Payment Not Scheduled
            </span>
          </div>
          <scheduled-payments
            v-if="!showAutopayPlan"
            :paymentDetails="paymentDetails"
            @openPrepay="openPrepay"
            @openPostpay="openPostpay"
          ></scheduled-payments>

          <autopay
            v-else
            :selectedPlan="selectedPlan"
            :paymentDetails="paymentDetails"
            @updateAutopayDate="
              (date) => {
                payDate = date;
              }
            "
            @closeAutopayPlan="showAutopayPlan = false"
            @deActivateAutopay="deActivateAutopay"
          />

          <v-divider></v-divider>

          <div v-if="visits && visits.length === 0" class="">
            <h4>Patient have no unpaid balances</h4>
          </div>
          <div
            class="bill__wrap"
            ref="prepay"
            v-if="prepayDue > 0 && !hidePrePay"
          >
            <div class="bill__card">
              <div class="bill__status-prepay"></div>
              <div class="bill__content">
                <div v-if="balance.prePayAppointment" class="bill__header">
                  <div class="bill__info">
                    <div class="bill__date">
                      {{ formatDate(balance.prePayAppointment.appDate) }}
                    </div>
                  </div>
                  <div class="bill__desc">
                    {{ balance.prePayAppointment.name }}
                  </div>
                </div>

                <div
                  class="bill__amount bill__amount-collapsed"
                  v-if="!isPrepayOpened"
                >
                  Due ${{ priceFormat(calcMinPayment) }}
                </div>
                <div
                  class="bill__amount"
                  v-if="isPrepayOpened"
                  @click="hideBill"
                >
                  Total Due ${{ priceFormat(prepayDue) }}
                </div>
                <button
                  class="btn btn-primary no-border bill__button"
                  @click.prevent="openPayModal(prepayDue, locID, 'prePay')"
                  v-if="isPrepayOpened"
                >
                  Pay total ${{ priceFormat(prepayDue) }}
                </button>

                <transition name="fade">
                  <div v-if="isPrepayOpened" class="bill__prepay-wrap">
                    <details-info
                      ref="detailsInfo"
                      :details="paymentDetails"
                    ></details-info>
                  </div>
                </transition>
                <prePayForm
                  v-if="isPrepayOpened"
                  :openPayModal="openPayModal"
                  :hideBill="hideBill"
                  :amount="priceFormat(calcMinPayment)"
                  :max="prepayDue"
                  :locID="locID"
                />

                <button
                  class="btn btn-third bill__button"
                  v-if="!isPrepayOpened"
                  @click.prevent="showBill('prepay')"
                >
                  View full details
                </button>
              </div>
            </div>
          </div>

          <div
            class="bill__wrap"
            :ref="i"
            v-for="(visit, i) in visits"
            :key="i"
          >
            <div
              class="bill__card"
              :class="{ collapsed: collapsedData(i), uncollapsed: isData(i) }"
            >
              <div class="bill__status"></div>
              <div class="bill__content">
                <div class="bill__header">
                  <div class="bill__info">
                    <div class="bill__date">
                      {{ formatDate(visit.procs[0].procDate) }}
                    </div>
                    <div class="bill__desc">Visit# {{ i }}</div>
                  </div>
                  <div class="bill__desc">{{ visit.concatName }}</div>
                </div>
                <div
                  class="bill__amount bill__amount-collapsed"
                  v-show="collapsedData(i)"
                >
                  Due ${{ priceFormat(visit.calcSum) }}
                </div>
                <div class="bill__amount" v-show="isData(i)" @click="hideBill">
                  Total Due ${{ priceFormat(visit.calcSum) }}
                </div>

                <transition name="fade">
                  <div class="bill__table-wrap" v-show="isData(i)">
                    <table class="details-table bill__table" ref="table">
                      <tbody v-for="(proc, j) in visit.procs" :key="j">
                        <tr>
                          <td colspan="2" class="bill__table-description">
                            {{ proc.procDesc }}
                          </td>
                        </tr>
                        <tr>
                          <td class="noborder">Total Charge</td>
                          <td class="noborder">
                            ${{ priceFormat(proc.fullPrice) }}
                          </td>
                        </tr>
                        <tr>
                          <td class="noborder">Your Previous Payment</td>
                          <td class="noborder">
                            -${{ priceFormat(proc.patPaid) }}
                          </td>
                        </tr>
                        <tr>
                          <td class="noborder">Insurance & Adjustment</td>
                          <td class="noborder">
                            -${{ priceFormat(proc.adjSum) }}
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td>Your balance</td>
                          <td>${{ priceFormat(visit.calcSum) }}</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </transition>
                <postPayForm
                  :openPayModal="openPayModal"
                  :getCharges="getCharges"
                  :hideBill="hideBill"
                  :visit="visit"
                  :index="i"
                  v-show="isData(i)"
                />
                <button
                  class="btn btn-third bill__button"
                  v-show="collapsedData(i)"
                  @click.prevent="showBill(i)"
                >
                  View full details
                </button>
              </div>
            </div>
          </div>
          <div class="details-history">
            <div @click="toggleHistory" class="details-history__toggle">
              <div
                class="details-history__button"
                :class="{ 'is-open': showHistory }"
              ></div>
              Payment History
            </div>
          </div>
          <v-simple-table v-if="showHistory">
            <template v-slot:default>
              <thead>
                <th class="text-left">Date</th>
                <th class="text-left">Ref Number</th>
                <th class="text-left">Auth Code</th>
                <th class="text-left">Payment Post ID</th>
                <th class="text-left">Amount</th>
                <th class="text-left">Payment Type</th>
                <th class="text-left">CC Lsst 4</th>
                <th class="text-left">Visit ID</th>
                <th class="text-left">Status</th>
              </thead>
              <tbody>
                <tr v-if="!transactions.length">
                  <td colspan="9">No payments yet</td>
                </tr>
                <tr v-for="(transaction, index) in transactions" :key="index">
                  <td>{{ shortFormatDate(transaction.transaction_date) }}</td>
                  <td>{{ transaction.ref_number }}</td>
                  <td>{{ transaction.auth_code }}</td>
                  <td>{{ transaction.data_payment_id }}</td>
                  <td>{{ transaction.amount }}</td>
                  <td>{{ transaction.paymentType }}</td>
                  <td>
                    {{ transaction.Card ? transaction.Card.lastfour : '' }}
                  </td>
                  <td>
                    {{
                      transaction.paymentType === 'postPay'
                        ? transaction.transaction_key
                        : ''
                    }}
                  </td>
                  <td>success</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>

        <div class="patients-card">
          <div class="patients-card__header">Notifications</div>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <th class="text-left">Date ({{ localTZ }})</th>
                <th class="text-left">To</th>
                <th class="text-left">Message</th>
                <th class="text-left">Type</th>
                <th class="text-left">Status</th>
              </thead>
              <tbody>
                <tr v-if="!messages.length">
                  <td colspan="5">No notifications yet</td>
                </tr>
                <tr v-for="(message, index) in messages" :key="index">
                  <td>{{ formatDate(message.createdAt) }}</td>
                  <td>{{ message.to }}</td>
                  <td>{{ message.body }}</td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </div>
      <pay-modal
        ref="payModal"
        v-if="patient && card"
        :optionTitle="optionTitle"
        :paymentType="paymentType"
        :paymentData="paymentData"
        :autopayState="autopay"
        @updateDetails="updateDetails"
      ></pay-modal>
    </div>
  </v-container>
</template>

<script>
import moment from 'moment';
import 'moment-timezone';
import { createNamespacedHelpers } from 'vuex';
import { helpers } from '@/mixins/helpers';
import manageCards from '../../components/manage-cards/manage-cards.vue';
import scheduledPayments from '../../components/scheduled-payments/scheduled-payments.vue';
import autopay from '../../components/autopay/autopay.vue';
import payModal from '../../modals/pay-modal.vue';
import detailsInfo from '../../components/details-info/details-info.vue';
import postPayForm from '../patients-postpay-form.vue';
import prePayForm from '../patients-prepay-form.vue';
import Loader from '@/components/loader/loader.vue';

const { mapGetters: customerGetters, mapActions: customerActions } =
  createNamespacedHelpers('customer');
const { mapGetters: patientGetters } = createNamespacedHelpers('patient');
const { mapMutations: sharedMutations } = createNamespacedHelpers('shared');
const { mapActions: workflowDetailsActions } =
  createNamespacedHelpers('workflows/details');
const { mapActions: credsActions, mapGetters: credentialsGetters } =
  createNamespacedHelpers('credentials');

export default {
  name: 'patientsPopup',
  mixins: [helpers],
  components: {
    manageCards,
    scheduledPayments,
    autopay,
    payModal,
    detailsInfo,
    postPayForm,
    prePayForm,
    Loader,
  },
  data() {
    return {
      notif: false,
      patient: {},
      loading: true,
      insLoading: false,
      balance: null,
      insurance: null,
      messages: [],
      payDate: null,
      transactions: [],
      showDetails: false,
      openedId: null,
      showHistory: false,
      notifOn: true,
      notifOff: false,
      localTZ: 'UTC',
      showAutopayPlan: false,
      paymentType: null,
      paymentsArray: [],
      currentLocationID: null,
      selectedPaymentAmount: null,
      visitsID: null,
      charges: null,
      autopay: false,
      paymentDetails: null,
      selectedPlan: null,
      updatingPatient: false,
      loaderMessage: '',
      authenticationId: null,
      pverifyResponse: null,
    };
  },
  computed: {
    ...customerGetters({
      customer: 'CUSTOMER',
      selected_customer: 'SELECTED_CUSTOMER',
      featureFlags: 'FEATURE_FLAGS',
    }),
    ...patientGetters({
      card: 'MAIN_CARD',
      veuxPatient: 'PATIENT',
    }),
    ...credentialsGetters({
      authentications: 'AUTHENTICATIONS',
    }),
    patient_id() {
      if (this.$route.params.patientId) {
        return Number(this.$route.params.patientId);
      }

      return null;
    },
    visits() {
      return this.patient && this.balance && this.balance.overallSum
        ? this.balance.visits
        : [];
    },
    locID() {
      return this.customer.CustomersFeature.defaultLocation;
    },
    prepay_batch() {
      if (this.autopayment?.payment_type === 'prePay') {
        return this.autopayment;
      }
      return null;
    },
    prepay_autopay_amount() {
      if (!this.prepay_batch) return 0.0;
      const paid_off = this.patient.paid_off || 0;
      const restToPay = parseFloat(this.patient.cost) - parseFloat(paid_off);
      if (restToPay < this.prepay_batch.regular_payment_amount)
        return restToPay;
      else return this.prepay_batch.regular_payment_amount;
    },
    postpay_batch() {
      if (this.autopayment?.payment_type === 'postPay') {
        return this.autopayment;
      }
      return null;
    },
    postpay_autopay_amount() {
      if (!this.postpay_batch) return 0.0;
      const restToPay =
        this.postpay_batch && this.postpay_batch.notes
          ? this.calcChargesBalance(this.postpay_batch)
          : this.patientBalance;

      if (restToPay < this.postpay_batch.regular_payment_amount)
        return restToPay;
      else return this.postpay_batch.regular_payment_amount;
    },
    autopay_type() {
      if (!this.patient) return false;

      if (
        this.patient.ob_status &&
        parseFloat(this.patient.cost) >
          parseFloat(this.patient.paid_off || 0) &&
        this.prepay_batch &&
        this.prepay_batch.batch_is_active
      )
        return 'prepay';
      if (
        this.postpay_batch &&
        this.postpay_batch.batch_is_active &&
        this.balance.overallSum
      )
        return 'postpay';

      // default
      return false;
    },
    hidePrePay() {
      return !this.patient || !this.patient.ob_status;
    },
    denominator() {
      if (this.patient && this.patient.denominator)
        return this.patient.denominator;
      else return this.customer.paymentDenominator;
    },
    calcMinPayment() {
      if (
        this.customer.CustomersFeature &&
        this.customer.CustomersFeature.prepayCheck === false
      ) {
        return 0;
      }
      if (this.$store.getters['customer/FEATURE_FLAGS'].payment_v2) {
        return 0;
      }
      return this.prepayDue > this.singlePrice
        ? this.singlePrice
        : this.prepayDue;
    },
    singlePrice() {
      if (this.paymentDetails && this.paymentDetails.patient) {
        let paymentInfo = this.paymentDetails.patient;

        return paymentInfo.cost ? paymentInfo.cost / this.denominator : 0;
      }

      return 0;
    },
    prepayDue() {
      if (!this.patient) {
        return 0;
      }
      let prepay = +this.patient.cost - this.patient.paid_off;
      return prepay > 0 ? prepay : 0;
    },
    calculatedPrepayAmount() {
      if (!this.patient.cost || !this.denominator) return 0;
      const rawPaymentAmount = this.patient.cost / this.denominator;
      const prepayBalance = this.patient.cost - this.patient.paid_off;
      if (rawPaymentAmount < prepayBalance) return rawPaymentAmount;
      else return prepayBalance;
    },
    payButtonText() {
      return `Balance $${this.totalDue.toFixed(2)}`;
    },
    optionTitle() {
      return `$${this.priceFormat(this.selectedPaymentAmount)} Payment ${
        this.customer.CustomerName
      }`;
    },
    paymentData() {
      let data = {
        pid: this.patient && this.patient.id,
        card_id: this.card.id,
        amount: this.selectedPaymentAmount,
        currency: 'USD',
        customer_id: this.customer.id,
        denominator: this.updatedDenominator,
        locID: this.currentLocationID,
      };
      if (this.paymentsArray.length) data.paymentsArray = this.paymentsArray;
      if (this.visitsID) data.visitsID = this.visitsID;
      if (this.charges) data.charges = this.charges;
      return data;
    },
    totalDue() {
      if (this.$store.getters['customer/FEATURE_FLAGS'].payment_v2) {
        return +this.balance?.overallSum;
      }
      return this.autopay
        ? +this.balance?.overallSum
        : +this.balance?.overallSum +
            (this.hidePrePay ? 0 : this.calcMinPayment);
    },
    isPrepayOpened() {
      return this.openedId === 'prepay';
    },
    autopayment() {
      return this.veuxPatient?.autoPayment;
    },
  },
  watch: {
    veuxPatient(val) {
      this.patient = val;
    },
  },
  mounted() {
    this.setLocalTimezone();
    this.getAdditionalInfo();
    this.fetchAuthentications();
    this.getFeatureFlags();
  },
  methods: {
    ...sharedMutations({
      addNotification: 'ADD_NOTIFICATION',
    }),
    ...credsActions(['fetchAuthentications']),
    ...workflowDetailsActions(['executeSingleAction']),
    ...customerActions(['getFeatureFlags']),
    openPrepay() {
      this.selectedPlan = 'prePay';
      this.showAutopayPlan = true;
    },
    openPostpay() {
      this.selectedPlan = 'postPay';
      this.showAutopayPlan = true;
    },
    calcChargesBalance(batch) {
      if (batch?.payment_type !== 'postPay') return 0.0;
      const chargesList = batch.notes.split(',');

      const chargesBalances = chargesList.map((batchChargeID) => {
        for (var visit_id in this.visits) {
          const visit = this.visits[visit_id];
          const charge = visit.procs.find((cc) => cc.chargeID == batchChargeID);
          if (charge) return charge.balance;
        }
        return 0.0;
      });

      let chargesBalance = chargesBalances.reduce((acc, b) => acc + b, 0.0);
      return parseFloat(chargesBalance.toFixed(2));
    },
    async updatePatient() {
      this.updatingPatient = true;
      this.loaderMessage = 'Updating patient...';
      const URL = '/proxy/force-patients-insert';
      const customerID =
        this.customer.customer_id === 0
          ? this.selected_customer.customer_id
          : this.customer.customer_id;

      const updateResponse = await window.axios.post(URL, {
        customer_id: customerID,
        pids: [this.patient.patient_id],
      });
      if (updateResponse.data.success) {
        await this.getAdditionalInfo();
        this.addNotification({
          type: 'success',
          text: 'Patient updated successfully',
          title: `Success`,
        });
      } else {
        this.addNotification({
          type: 'error',
          text: 'Patient updating failed',
          title: 'Error',
        });
      }
      this.updatingPatient = false;
      this.loaderMessage = '';
    },
    formatDate(date) {
      return moment(date).tz(this.localTZ).format('MMMM Do YYYY');
    },
    formatPaymentDate(date) {
      return moment(date).utc().format('MMMM Do YYYY');
    },
    toggleHistory() {
      this.showHistory = !this.showHistory;
    },
    collapsedData(i) {
      return !this.openedId || (this.openedId && this.openedId !== i);
    },
    isData(i) {
      return this.openedId && this.openedId === i;
    },
    showBill(id) {
      this.openedId = id;
    },
    hideBill() {
      this.openedId = null;
    },
    toggleDetails() {
      this.showDetails = !this.showDetails;
    },
    getDate(date) {
      return moment(date).utc().format('L');
    },
    getCharges(procs) {
      let charges = [];
      if (procs) {
        for (let proc of procs) {
          charges.push(`${proc.id} - ${proc.chargeID}`);
        }
      } else {
        for (let visit in this.visits) {
          for (let proc of this.visits[visit].procs) {
            charges.push(`${proc.id} - ${proc.chargeID}`);
          }
        }
      }
      return charges.join();
    },
    getVisits() {
      let visitsArr = [];
      for (let visitID in this.visits) {
        visitsArr.push(visitID);
      }
      return visitsArr;
    },
    changeNotifications() {
      this.notif = !this.notif;
    },
    deActivateAutopay() {
      this.showAutopayPlan = false;
      this.getAdditionalInfo();
    },
    openPayModal(amount, locID, type, charges, visitID) {
      this.selectedPaymentAmount = amount;
      this.currentLocationID = locID;
      this.paymentType = type;
      this.charges = charges || null;
      if (visitID && visitID.length) this.visitsID = visitID ? [visitID] : [];
      else this.visitsID = null;
      this.paymentsArray = [];
      if (!this.card) {
        console.error(`Card not defined`);
        this.addNotification({
          type: 'error',
          text: 'Patient has no card',
          title: `Error`,
        });
        return;
      }
      this.$refs.payModal.open();
    },
    doublePayment(prepPayAmount, postPayAmount, locID, type, charges, visits) {
      this.selectedPaymentAmount = this.totalDue;
      this.paymentsArray = this.autopay
        ? [0, postPayAmount]
        : [prepPayAmount, postPayAmount];
      this.paymentType = type;
      this.currentLocationID = locID;
      this.charges = charges || null;
      if (visits && visits.length) this.visitsID = visits;
      else this.visitsID = null;
      if (!this.card) {
        console.error(`Card not defined`);
        this.addNotification({
          type: 'error',
          text: 'Patient has no card',
          title: `Error`,
        });
        return;
      }
      this.$refs.payModal.open();
    },
    async updatePatientBenefit() {
      if (!this.authenticationId) return;
      try {
        this.insLoading = true;
        const params = {
          firstname: this.patient.firstname,
          lastname: this.patient.lastname,
          date_of_birth: this.patient.dob,
          member_id: this.insurance.member_id,
          insurance_company: this.insurance.company_name,
          pid: this.patient.id,
        };
        const objectKeys = {};
        Object.keys(params).forEach((param) => {
          objectKeys[param] = `Object.${[param]}`;
        });
        const options = {
          data: { ...objectKeys, include_model: false },
          action: 'get_benefits_pverify',
          input: params,
          creds: this.authenticationId,
        };
        this.pverifyResponse = await this.executeSingleAction(options);
        this.toggleDetails();
        //   .then(() => this.getPaymentDetails())
      } catch (error) {
        console.error(error);
      } finally {
        this.insLoading = false;
      }
    },
    changeSkipCostEstimate() {
      const URL = '/proxy/set-cost-estimation-skip';
      const customerID =
        this.customer.customer_id === 0
          ? this.selected_customer.customer_id
          : this.customer.customer_id;

      return window.axios
        .post(URL, {
          value: this.patient.skip_cost_extimation,
          pid: this.patient.patient_id,
          customer_id: customerID,
        })
        .catch((e) => console.error(e));
    },
    changeOptOut() {
      const URL = '/proxy/set-opt-out';
      return window.axios
        .post(URL, {
          value: this.patient.opt_out,
          pid: this.patient_id,
        })
        .then((response) => {
          if (response && response.data && response.data.success) {
            return;
          }
        })
        .catch((e) => console.error(e));
    },
    shortFormatDate(date) {
      if (!date) return '';
      return moment(date).format('L');
    },
    setLocalTimezone() {
      this.localTZ = moment.tz.guess();
    },
    updateAutopayDate(val) {
      this.payDate = val;
    },
    sendLink() {
      const URL = '/proxy/notify-by-phone';
      const customerName =
        this.customer.customer_id === 0
          ? this.selected_customer.CustomerName
          : this.customer.CustomerName;

      window.axios
        .post(URL, {
          customer_name: customerName,
          phone: this.patient.cellphone,
        })
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.addNotification({
              type: 'success',
              text: 'Link sent successfully',
              title: `Success`,
            });
          } else throw new Error(`Send link response invalid`);
        })
        .catch((e) => {
          this.addNotification({
            type: 'error',
            text: e,
            title: 'Error',
          });
        });
    },
    async updateDetails() {
      await this.getPatientBalance();
      await this.getPaymentDetails();
    },
    getPatientDetails() {
      const customerID =
        this.customer.customer_id === 0
          ? this.selected_customer.customer_id
          : this.customer.customer_id;

      const URL = '/proxy/api/data/get-patient';
      return window.axios
        .post(URL, {
          pid: this.patient_id,
          customer_id: customerID,
        })
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.patient = response.data.patient;
            this.$store.commit('patient/SET_PATIENT', this.patient);
          } else throw new Error(`Patient response invalid`);
        });
    },
    getPatientBalance() {
      const customerID =
        this.customer.customer_id === 0
          ? this.selected_customer.customer_id
          : this.customer.customer_id;

      const URL = '/proxy/get-patient-balance';
      return window.axios
        .post(URL, {
          customer_id: customerID,
          pid: this.patient.patient_id,
        })
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.$store.commit(
              'patient/ADD_PATIENT_BALANCE',
              response.data.balanceInfo
            );
            this.balance = response.data.balanceInfo;
          } else throw new Error(`Balance response invalid`);
        });
    },
    getPatientMessages() {
      const customerID =
        this.customer.customer_id === 0
          ? this.selected_customer.customer_id
          : this.customer.customer_id;
      const URL = '/proxy/get-patient-messages';
      return window.axios
        .post(URL, {
          customer_id: customerID,
          pid: this.patient.patient_id,
        })
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.messages = response.data.output.messages;
          } else throw new Error(`Messages response invalid`);
        });
    },
    async getPaymentHistory() {
      await this.$store.dispatch('patient/getPaymentHistory');
      this.transactions = this.patient.patientHistory
        ? this.patient.patientHistory
        : [];
    },
    getpatientAutopay() {
      const customerID =
        this.customer.customer_id === 0
          ? this.selected_customer.customer_id
          : this.customer.customer_id;

      const URL = '/proxy/get-autopay-dates';
      return window.axios
        .post(URL, {
          customer_id: customerID,
          pid: this.patient_id,
        })
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.$store.commit(
              'patient/SET_AUTOPAYMENT',
              response.data.batches
            );
          } else throw new Error(`Autopay response invalid`);
        });
    },
    getPaymentDetails() {
      const customerID =
        this.customer.customer_id === 0
          ? this.selected_customer.customer_id
          : this.customer.customer_id;
      //  get-payment-details.
      const URL = '/proxy/api/payment/get-payment-details';
      return window.axios
        .post(URL, {
          customer_id: customerID,
          pid: this.patient_id,
        })
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.paymentDetails = response.data;

            // if (!response.data.patient.ob_status) this.hidePrePay = true;
            this.insurance = response.data.patientInsurance;
            this.patient.ob_status = response.data.patient.ob_status;
            this.$store.commit('patient/SET_OB_STATUS', this.patient.ob_status);
          } else throw new Error(`Insurance response invalid`);
        });
    },
    getCardsByPatient() {
      return window.axios
        .post('/proxy/api/payment/get-cards-by-patient', {
          pid: this.patient.id,
        })
        .then((response) => {
          if (response.data && response.data.success) {
            const cards = response.data.items || [];
            cards.forEach((card) => {
              card.selected = false;
            });
            const mainCard = cards.find((c) => c.is_primary === true);
            if (mainCard) mainCard.selected = true;
            this.$store.commit('patient/SET_PATIENT_CARDS', cards);
            this.$store.commit('patient/SET_MAIN_CARD', mainCard);
          } else {
            this.$store.commit('patient/SET_PATIENT_CARDS', []);
            this.$store.commit('patient/SET_MAIN_CARD', null);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getAdditionalInfo() {
      this.updatingPatient = true;
      this.loading = true;
      this.loaderMessage = 'Getting patient details...';
      return this.getPatientDetails()
        .then(() => this.getCardsByPatient())
        .catch((e) => console.error(e))
        .then(() => this.getPatientBalance())
        .catch((e) => console.error(e))
        .then(() => this.getPatientMessages())
        .catch((e) => console.error(e))
        .then(() => this.getPaymentDetails())
        .catch((e) => console.error(e))
        .then(() => this.getPaymentHistory())
        .catch((e) => console.error(e))
        .then(() => this.getpatientAutopay())
        .catch((e) => console.error(e))
        .then(() => {
          this.updatingPatient = false;
          this.loading = false;
          this.loaderMessage = '';
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/sass/variables.scss';
@import '../../_buttons.scss';
@import './patients-popup.scss';
.patient-container {
  background-color: #e7e7e7 !important;
}
</style>
